// Returns list of degree abbreviations (MD, MBA, etc) for card titles
export function getDegreeAbbreviations(degrees) {
  if (!degrees || degrees.length == 0) {
    return '';
  }

  let degreeString = ', '

  // Degree abbreviation will be text between parenthesis, i.e. Medical Doctor (MD)
  for (const [i, degree] of degrees.entries()) {
    // Matches any text between an open and close parenthesis
    degreeString += degree.match(/\(([^)]+)\)/)[1];
    if (i < degrees.length - 1) {
      degreeString += ', '
    }
  }

  return degreeString;
}
